import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ajaxGetPackingLists } from '../services/packinglistService';
import { ajaxGetDealersAutocomplete } from '../services/dealerService';
import { toDateText, downloadFile, viewFile } from '../helpers';
import useWorkspace from '../hooks/useWorkspace';
import LoadingSpinner from '../components/LoadingSpinner';
import Page from '../components/Page';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EnhancedTable from '../components/EnhancedTable'
import Grid from '@mui/material/Grid';
import StyledSearchDate from '../components/StyledSearchDate';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const downloadPackingListFile = (id, fileType, fileName, fileExt) =>
  <div style={{ textAlign: 'center' }}>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        viewFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <PictureAsPdfIcon fontSize="small"/>
    </IconButton>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        downloadFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <FileDownloadIcon fontSize="small"/>
    </IconButton>
  </div>

const dateFormat = (v) => v ? toDateText(v) : '-';

const columns = [
  {
    id: 'date',
    label: 'Order Date',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  {
    id: 'oc_ref',
    label: 'OC Ref',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'dealer_name',
    label: 'Company',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'po_number',
    label: 'PO Number',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'shipping_date',
    label: 'Shipping Date',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  {
    id: 'customer',
    label: 'Customer',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'country',
    label: 'Country',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'revision',
    label: 'Revision',
    minWidth: 100
  },
  {
    id: 'packing_pdf',
    label: 'Packing List pdf',
    minWidth: 100,
    format: (v, row) => downloadPackingListFile(row.id, 'packing_list/packing_list_pdf', row.oc_ref, 'pdf')
  }
];


export default function PackingListList() {
  const [workspace, setWorkspace] = useWorkspace(true, 'packingListPage', { table :{}, filter: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(workspace.filter.searchTerm || '');
  const [startDate, setStartDate] = useState(workspace.filter.startDate || null);
  const [endDate, setEndDate] = useState(workspace.filter.endDate || null);
  const [autocomplete, setAutocomplete] = useState(workspace.filter.autocomplete || null);
  const [autocompleteList, setAutocompleteList] = useState([]);

  const navigate = useNavigate();

  const onTableParams = useCallback((params) => {
    if ( isLoading ) {
      return;
    }
    const {pageItems, ...restParams} = params;
    setWorkspace(prev => ({
      ...prev,
      table: {
        ...prev.table,
        ...restParams
      }
    }));
  }, [isLoading, setWorkspace]);

  useEffect(() => {
    if ( isLoading ) {
      return;
    }
    setWorkspace(prev => ({
      ...prev,
      filter: {
        ...prev.filter,
        searchTerm, startDate, endDate,
        autocomplete
      }
    }));
  }, [
    isLoading, setWorkspace,
    searchTerm, startDate, endDate,
    autocomplete
  ]);

  useEffect(() => {
    ajaxGetPackingLists({})
      .then((res) => {
        setItems(res.data);
      })
      .then(() => {
        return ajaxGetDealersAutocomplete().then((dealerRes) => {
          const dealerList = dealerRes.data;
            const autocompleteList = [
              { id: -1, name: 'All Companies' },
              ...dealerList.map(d => ({ id: d.id, name: d.name }))
            ]
            setAutocompleteList(autocompleteList);
            if ( !autocomplete || !autocompleteList.find(d => d.id === autocomplete.id) ) {
              setAutocomplete(autocompleteList.find(d => d.id === -1));
            }
            setIsLoading(false);
      }).catch(() => {
          setAutocomplete(null);
        setIsLoading(false);
      });
      })
      .catch(() => {
        setItems([]);
        setIsLoading(false);
      });
  }, [autocomplete]);

  const onClearFilter = () => {
    setSearchTerm('');
    setStartDate(null);
    setEndDate(null);
    setAutocomplete(autocompleteList.find(d => d.id === -1));
  };

  const handleDealerNameSearch = (event, value) => {
    setAutocomplete(value);
  };

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  useEffect(() => {
    const results = items.filter((item) => {
      let testStartDate = true;
      let testEndDate = true;
      let searchByTerm = true
      let testDealer = true;

      if(searchTerm) {
        const fields = ['po_number', 'customer', 'country']
        searchByTerm = fields.reduce((acc, field) => acc || (item[field] && item[field].toLowerCase().includes(searchTerm)), false); 
      }

      if (startDate) {
        testStartDate = item.date >= startDate;
      }
      if (endDate) {
        testEndDate = item.date <= endDate;
      }

      if (autocomplete && autocomplete.id !== -1) {
        testDealer = item.dealer_name === autocomplete.name;
      }
      
      return testStartDate && testEndDate && searchByTerm && testDealer;
    });

    setSearchResults(results);
  }, [searchTerm, items, startDate, endDate, autocomplete]);

  return (
    <Page title="Packing Lists" maxWidth={false} sx={{ display: 'flex', flexDirection: 'column' }}>
      {
        isLoading ?
        <LoadingSpinner />
        :
        <React.Fragment>
          <Box>
            <Grid container spacing={1}>
              <Grid xs={12} sm={4} item>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="Search"
                  placeholder="Search keyword"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={searchChangeHandle}
                  value={searchTerm}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid xs={12} sm={4} item>
                <Autocomplete
                  disablePortal
                  sx={{ width: '100%' }}
                  size="small"
                  value={autocomplete}
                  options={autocompleteList}
                  isOptionEqualToValue={(option, value) => (value && option.id === value.id)}
                  getOptionLabel={(option) => `${option.name} [${option.id}]`}
                  onChange={handleDealerNameSearch}
                  renderInput={(params) => <TextField {...params} label="Dealer Name" />}
                />
              </Grid>
              <Grid xs={12} sm={4} item>
                <StyledSearchDate
                  startDate={startDate}
                  endDate={endDate}
                  onStartDate={setStartDate}
                  onEndDate={setEndDate}
                  gridProps={{ columnSpacing: 1 }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  color="info"
                  sx={{ margin: '10px 0' }}
                  startIcon={<AddIcon />}
                  onClick={() => navigate('/packinglists/add')}
                >
                  New packing list
                </Button>
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ margin: '10px 0' }}
                  startIcon={<FilterListOffIcon />}
                  onClick={onClearFilter}
                >
                  Clear Filter
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <Box sx={{ position: 'absolute', inset: '0px 0px 10px 0px' }}>
              <EnhancedTable
                border
                fullHeight
                stickyColumn
                fontSize="12px"
                columns={columns}
                items={searchResults}
                onRowClick={(id) => navigate('/packinglists/edit/' + id)}
                order={workspace.table.order || 'desc'}
                orderBy={workspace.table.orderBy || 'date'}
                page={workspace.table.pageIndex || 0}
                rowsPerPage={workspace.table.rowsPerPage || 50}
                onTableParams={onTableParams}
              />
            </Box>
          </Box>
        </React.Fragment>
      }
    </Page>
  );
}
