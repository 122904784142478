import * as React from 'react';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import StyledMainGrid from './StyledMainGrid';
import StyledCardFormContent from './StyledCardFormContent';

export default function StyledCardSearch({ sx, onSearch, ...restProps }) {
  return (
    <StyledCardFormContent sx={{ mb: { sm: '-72px' }, ...sx }}>
      <StyledMainGrid>
        <Grid xs={12} item>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="Search"
            placeholder="Search keyword"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={onSearch}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            {...restProps}
          />
        </Grid>
      </StyledMainGrid>
    </StyledCardFormContent>
  );
}
