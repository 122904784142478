import React from 'react';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)((props) => ({
  [`&.MuiAlert-root`]: {
    whiteSpace: 'pre-line'
  },
}));

export default function useAlert(defaultValue, severity) {
  const [alert, setAlert] = React.useState(defaultValue);

  function showAlert() {
    return alert ? <StyledAlert severity={severity}>{alert}</StyledAlert> : '';
  }

  const resetAlert = () => {
    setAlert(defaultValue);
  };

  return { alert, setAlert, showAlert, resetAlert };
}
