import { ajaxCall, formRequestData } from './ajaxService';

const constants = window.getConfig;
const ajaxPackingListsUrl = `${constants.ajax.baseUrl}/packinglists`;

const ajaxGetPackingLists = (params) =>
  new Promise((res, rej) => {
    if (params && params.PackingListId) {
      ajaxCall(ajaxPackingListsUrl + `/${params.PackingListId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(ajaxPackingListsUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSavePackingList = (params) =>
  new Promise((res, rej) => {
    ajaxCall(ajaxPackingListsUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxDeletePackingList = (params) =>
  new Promise((res, rej) => {
    if (params && params.PackingListId) {
      ajaxCall(ajaxPackingListsUrl + `/delete/${params.PackingListId}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

export {
  ajaxGetPackingLists,
  ajaxSavePackingList,
  ajaxDeletePackingList
};
