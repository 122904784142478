import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import validator from 'validator';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import { ajaxGetPackingLists, ajaxSavePackingList, ajaxDeletePackingList } from '../services/packinglistService';
import { ajaxGetOrdersAutocomplete, ajaxGetOrders } from '../services/orderService';
import StyledMainGrid from '../components/StyledMainGrid';
import UtcDatePicker from '../components/UtcDatePicker';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';
import ConfirmationButton from '../components/ConfirmationButton';
import PackingListProducts from '../components/PackingListProducts';
import { toDate, isFloat, countries, noty } from '../helpers';
import moment from 'moment';

export default function PackingList(props) {
  const backUrl = '/packinglists';
  const incomingParams = useParams();

  const [packingList, setPackingList] = useState({
    order_id: -1,
    po_number: '',
    shipping_date: 0,
    customer: '',
    country: '',
    show_logo: 1,
    products: []
  });
  
  const [orders, setOrders] = useState([]);
  const [orderChanged, setOrderChanged] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [plErrors, setPlErrors] = useState({});

  const paramId = (incomingParams && incomingParams.PackingListId ? incomingParams.PackingListId : -1);

  const allFields = useMemo(
    () => {
      const updatePackingListProducts = (products) => {
        setPackingList(pl => ({...pl, products: products}));
      };

      return [
        {
          key: 'order_id',
          type: 'autocomplete',
          label: 'Order OC Ref',
          dataset: orders.map(o=>({value:o.id, label:o.oc_ref})),
          disabled: isLoadingOrders
        },
        {
          key: 'po_number',
          type: 'text',
          label: 'PO Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_date',
          type: 'date',
          label: 'Shipping Date',
          gridProps: { xs: 6 }
        },
        {
          key: 'customer',
          type: 'text',
          label: 'Customer',
          gridProps: { xs: 6 }
        },
        {
          key: 'country',
          type: 'list',
          label: 'Country',
          dataset: countries.map(c => ({ value: c.name, label: c.name })),
          gridProps: { xs: 6 }
        },
        {
          key: 'show_logo',
          type: 'checkbox',
          label: 'Show Logo',
          gridProps: { xs: 6 }
        },
        {
          key: 'products',
          type: 'component',
          component: PackingListProducts,
          componentProps: {
            orderChanged: orderChanged,
            orderProducts: packingList.products,
            updatePackingListProducts: updatePackingListProducts,
            shipDate: packingList.shipping_date,
            errors: plErrors
          }
        }
      ]
    },
    [orders, packingList.products, packingList.shipping_date, orderChanged, isLoadingOrders, plErrors]
  );
  
  const requiredFields = useMemo(() => ['order_id', 'po_number'], []);

  const emailFields = useMemo(() => [], []);

  const floatFields = useMemo(() => [], []);

  const [errors, setErrors] = useState({});
  const [duplicateErrors, setDuplicateErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  
  useEffect(() => {
    setIsLoadingOrders(true);
    ajaxGetOrdersAutocomplete()
      .then((res) => {
        const { data } = res;
        setOrders(data);
        setIsLoadingOrders(false);
      })
      .catch(() => {
        setOrders([]);
        setIsLoadingOrders(false);
      });
  }, []);


  useEffect(() => {
    if (!props.add) {
      if (paramId !== -1) {
        const params = {PackingListId: paramId};
  
        ajaxGetPackingLists(params)
          .then((res) => {
            const { data } = res;
            setPackingList(data);
          })
          .catch(() => {
            setPackingList({});
          });
      }
    }
  }, [paramId, props.add]);

  const orderId = packingList ? packingList.order_id : null;
  useEffect(() => {
    const orderItem = (orders || []).find(o => o.id === orderId)
    if(orderChanged && orderItem) {
      // get products of the order
      ajaxGetOrders({OrderId: orderId})
      .then((res) => {
        const o = res.data;
        setPackingList(packingList=>({
          ...packingList,
          po_number: orderItem.customer_ref || orderItem.oc_ref || '',
          shipping_date: orderItem.shipping_date || 0,
          customer: orderItem.dealer_name || '',
          country: orderItem.dealer_country || '',
          products: o.products
        }));
      })
      .catch(() => {
        
      });
    }
  }, [orders, orderId, orderChanged, paramId]);

  const validateFields = useCallback(() => {
    const fields = { ...packingList };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && emailFields.includes(key) && !validator.isEmail(fieldValue)) {
        newErros[key] = 'Please enter a valid email address';
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && floatFields.includes(key) && !isFloat(fieldValue)) {
        newErros[key] = 'Please enter a valid number';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [packingList, allFields, requiredFields, emailFields, floatFields]);

  const validateProducts = useCallback(() => {
    let result = true;
    let newErrors = {};
    const products = packingList.products;
    for (let i in products) {
      const p = products[i];
      const lineCount = p.has_modem ? (p.pl_serial_count + 1) * p.quantity : p.pl_serial_count * p.quantity;
      // parse serial by both \r\n or \n
      const serials = p.pl_serial.split(/\r?\n/).map(s => s.trim()).filter(s => s);
      if ( serials.length !== lineCount ) {
        let errMsg = `Number of MAC/Serial Number is ${serials.length}/${lineCount}.`;
        if ( p.has_modem ) {
          errMsg += ` Check that you enter IMEI.`;
        }
        newErrors[p.id] = errMsg;
        result = false;
      }
    }
    setPlErrors(newErrors);
    console.log(newErrors);
    return result;
  }, [packingList.products]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  const handleSave = (revise, showNoty = true) => () => {
    if ( !validateFields() ) return false;
    if ( !validateProducts() ) return false;
    if(Object.keys(duplicateErrors).filter(k => !!duplicateErrors[k]).length > 0) {
      return false;
    }

    setSubmitOnce(true);
    setIsSaving(true);

    // prepar data
    const preparedPackingList = {
      ...packingList,
      products: packingList.products.map(p => ({...p, pl_name: p.pl_name === '' ? p.name : p.pl_name}))
    };
    const params = { ...preparedPackingList, ...!!revise ? {revise: true} : {} };
    ajaxSavePackingList(params)
      .then((res) => {
        if(showNoty){
          if (!res.error) {
            setPackingList(res.data);
            noty('Save successfully', 'success');
          }
        }
        setIsSaving(false)
      })
      .catch((e) => {
        if ( e.cause ) {
          let errorResult = {};
          for ( let k in e.cause ) {
            if ( allFields.filter(f => f.key === k).length > 0 ) {
              errorResult[k] = e.cause[k];
            }
          }
          setDuplicateErrors(errorResult);
        }
        setIsSaving(false)
        console.log(e.message)
      });
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
        key = typeof event === 'string' ? event : event.target.name,
        field = allFields.find(f=>f.key === key);

    if (key === 'order_id' &&  value !==null && packingList && packingList.order_id !== value.value ) {
      setOrderChanged(true);
    }

    if ( field && field.type === 'autocomplete') {
      setPackingList({ ...packingList, [key]: value ? value.value : null });
    } else {
      setPackingList({ ...packingList, [key]: value });
    }
  };

  const handleDelete = (id) => {
    ajaxDeletePackingList({ PackingListId: id })
      .then(() => navigate(backUrl))
      .catch((e) => console.log(e.message));
  };

  const getFieldValue = (key) => packingList && packingList[key] !== undefined && packingList[key] !== null ? packingList[key] : '';

  const getFieldPlaceholder = (key) => key === 'password' && !props.add ? 'leave blank to not change the password' : '';

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : (duplicateErrors && duplicateErrors[key] ? duplicateErrors[key] : '');

  const checkFieldError = (key) => (errors && errors[key]) || (duplicateErrors && duplicateErrors[key]);

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  return (
    <Page title="Add / Edit Packing List">
      <StyledCard>
        <StyledCardFormContent>
          {
            !props.add &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '25px', mt: '-20px' }}>
              <Link to={`/events/packinglist/${packingList.id}`}>
                View History
              </Link>
            </Box>
          }
          <StyledMainGrid columnSpacing alignItems="center" >
            {allFields.map((entry) => 
              <React.Fragment key={entry['key']}>
                { (entry['type'] === 'component') && 
                  <Grid item xs={12} {...entry['gridProps']}>
                    <entry.component {...entry.componentProps} />
                  </Grid>
                }
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      size="small"
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                      {...entry.multiline ? { multiline: true, rows: entry.lineNum ? entry.lineNum : 4 } : {}}
                    />
                  </Grid>
                }
                {
                  (entry['type'] === 'dollar') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      size="small"
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                      {...entry.multiline ? { multiline: true, rows: 4 } : {}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                      }}
                    />
                  </Grid>
                }
                {
                  (entry['type'] === 'date') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <UtcDatePicker
                      label={entry['label']}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          error: checkFieldError(entry['key']),
                          helperText: getFieldErrorText(entry['key'])
                        },
                      }}
                      value={getFieldValue(entry['key']) ? moment(toDate(getFieldValue(entry['key']))).unix() : null}
                      onChange={(value) => handleFieldChange(entry['key'], value)}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'autocomplete' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <Autocomplete
                        sx={{ width: '100%' }}
                        size="small" 
                        value={(entry.dataset || []).find(o=>o.value === getFieldValue(entry['key'])) || null}
                        options={(entry.dataset || [])}
                        isOptionEqualToValue={(option, value) => value && option.value === value.value}
                        getOptionLabel={(option) => `${option.label} [${option.value}]`}
                        onChange={(_,v)=>handleFieldChange(entry['key'], v)}
                        renderInput={(params) => <TextField {...params} label={entry['label']}/>}
                        disabled={!!entry.disabled}
                      />
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'list' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        size="small"
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        sx={{ width: '100%' }}
                      >
                        { (entry.dataset || []).map( c =>
                          <MenuItem value={c.value}>{c.label}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'checkbox' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControlLabel
                      control={<Checkbox name={entry['key']} value={1}/>}
                      onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                      checked={!!parseInt(getFieldValue(entry['key'], 10))}
                      label={entry['label']}
                      disabled={!!entry.disabled}
                    />
                  </Grid>
                }
                {
                  entry['divider'] && 
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                }
              </React.Fragment>
            )}
            <Grid item xs={12} />
            <Grid item xs={12} sm={9}>
              <Button disabled={isSaving} variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave(false)}>
                Save Packing List
              </Button>
              {
                paramId !== -1 && 
                <ConfirmationButton
                  buttonProps={{
                    disabled: isSaving,
                    variant: 'contained',
                    size: 'medium',
                    sx: { backgroundColor: 'green', mr: '20px' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={handleSave(true)}
                  buttonText={ "Revise Packing List" }
                  dialogText={ "Are you sure you want to revise the packing list?" }
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              }
              <Button variant="outlined" size="normal" style={buttonStyle} component={Link} to={backUrl}>
                Back
              </Button>
            </Grid>
            {
              paramId !== -1 && 
                <Grid item xs={12} sm={3} sx={{textAlign: "right"}}>
                  <ConfirmationButton
                    buttonProps={{
                      disabled: isSaving,
                      variant: 'contained',
                      size: 'medium',
                      sx: { backgroundColor: 'red' },
                      style: buttonStyle
                    }}
                    handleDialogClickYes={() => handleDelete(paramId)}
                    buttonText={ "Delete Packing List" }
                    dialogText={ "Are you sure you want to delete this packing list?" }
                    dialogYesText="Confirm"
                    dialogNoText="Cancel"
                  />
                </Grid>
            }
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
