import React, { useState, useCallback, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell  from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { toDateText } from '../helpers';

function ProductRow({index, product, handleFieldChange, moveOrderProduct, serialError, serialErrorText, shipDate}) {
  const dragRef = useRef(null)
  const previewRef = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: 'product',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!previewRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = previewRef.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      
      moveOrderProduct(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ opacity }, drag, preview] = useDrag({
    type: 'product',
    item: () => {
      return { id: product.id, index }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  })

  drag(dragRef)
  drop(preview(previewRef))

  const dateFormat = (v) => v ? toDateText(v) : '-';

  return (
    <TableRow style={{ opacity }} ref={previewRef} data-handler-id={handlerId}>
      <TableCell ref={dragRef} style={{ cursor: 'grab', width: '4%'}}>{index + 1}</TableCell>
      <TableCell style={{ maxWidth: 400, width: '40%'}}>
        <TextField
          name="pl_name"
          size="small"
          value={product.pl_name === '' ? product.name : product.pl_name}
          onChange={handleFieldChange}
          placeholder="Product"
          InputLabelProps={{ shrink: true }}
          fullWidth
          multiline
        />
      </TableCell>
      <TableCell align="center" style={{ maxWidth: 55, width: '5%'}}>
        {product.quantity}
      </TableCell>
      <TableCell style={{ maxWidth: 120}}>
        <TextField
          name="pl_serial"
          size="small"
          value={product.pl_serial}
          onChange={handleFieldChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          multiline
          error={serialError}
          helperText={serialErrorText}
          disabled={product.pl_serial_count === 0}
          placeholder={product.pl_serial_count === 0 ? 'N/A' : ''}
        />
      </TableCell>
      <TableCell style={{ maxWidth: 120}}>
        <TextField
          name="pl_box"
          size="small"
          value={product.pl_box}
          onChange={handleFieldChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          multiline
        />
      </TableCell>
      <TableCell align="center" style={{ maxWidth: 55, width: '10%'}}>
        {dateFormat(shipDate ? shipDate + (product.warranty * 24 * 60 * 60) : null)}
      </TableCell>
    </TableRow>
  );
}

export default function PackingListProducts(props) {
  const {orderProducts, updatePackingListProducts, orderChanged, errors, shipDate} = props;

  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    if(orderChanged) {
      setNeedUpdate(true);
    }
  }, [orderChanged]);

  useEffect(() => {
    if (needUpdate) {
      const newProducts = orderProducts.map(p=>{
        return {...p};
      });
      newProducts && updatePackingListProducts(newProducts);
      setNeedUpdate(false)
    }
  }, [needUpdate, orderProducts, updatePackingListProducts]);

  const handleFieldChange = (index) => (event) => {
    const key = event.target.name;
    const  value = event.target.inputMode === 'numeric' 
      ? parseFloat(event.target.value.replace(/\D/g,'') || 0) 
      : event.target.value;

      updatePackingListProducts(orderProducts.map((item, i) => i === index ? {...item, [key]: value} : item ));
  }

  const moveOrderProduct = useCallback((dragIndex, hoverIndex) => {
    updatePackingListProducts(
        update(orderProducts, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, orderProducts[dragIndex]],
          ],
        }),
      )
  }, [orderProducts, updatePackingListProducts]);

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : '';
  const checkFieldError = (key) => (errors && errors[key]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        { orderProducts.length === 0
          ? <div style={{ textAlign: 'center'}}>This packing list has no products</div>
          : <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '1px'}}>#</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>MAC/Serial Number</TableCell>
                  <TableCell>Box</TableCell>
                  <TableCell>Warranty</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderProducts.map((p,i)=>
                  <ProductRow
                    key={p.id}
                    index={i}
                    product={p}
                    handleFieldChange={handleFieldChange(i)}
                    moveOrderProduct={moveOrderProduct}
                    serialError={checkFieldError(p.id)}
                    serialErrorText={getFieldErrorText(p.id)}
                    shipDate={shipDate}
                  />
                )}
              </TableBody>
            </Table>
        }
      </DndProvider>
    </div>
  );
}