import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ajaxGetEvents, ajaxGetOrderEvents, ajaxGetPackingListEvents } from '../services/eventsService';
import { ajaxGetOrders, ajaxGetQuotations } from '../services/orderService';
import { ajaxGetPackingLists } from '../services/packinglistService';
import {
  ajaxGetOrders as ajaxGetProductionOrders,
  ajaxGetQuotations as ajaxGetProductionQuotations
} from '../services/productionOrderService';
import {
  ajaxGetOrders as ajaxGetShippingOrders,
  ajaxGetQuotations as ajaxGetShippingQuotations
} from '../services/shippingOrderService';
import inMemoryUser from '../services/inMemoryUser';
import Page from '../components/Page';
import Box from '@mui/material/Box';
import EnhancedTable from '../components/EnhancedTable'
import LoadingSpinner from '../components/LoadingSpinner';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toDateTimeText, copyToClipboard } from '../helpers';
import useDebounce from '../hooks/useDebounce';

export default function EventsList(props) {
  const incomingParams = useParams();
  const paramId = (incomingParams && incomingParams.ParamId ? incomingParams.ParamId : -1);

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState(false);
  const [packingList, setPackingList] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [processSearchTerm, setProcessSearchTerm] = useState('');

  const columns = [
    {
      id: 'time',
      label: 'Time',
      minWidth: 100,
      sx: { width: '200px' },
      format: (v) => toDateTimeText(v)
    },
    {
      id: 'username',
      label: 'User',
      format: (v, row) => (`${row.first_name} ${row.last_name}` || v)
    },
    {
      id: 'event',
      label: 'Event'
    },
    {
      id: 'data',
      label: 'Event Data',
      minWidth: 100,
      sx: { width: '100px' },
      format: (v, row) => {
        let json = JSON.stringify(JSON.parse(v), null, 2);
        return (
          <Box sx={{ textAlign: 'center' }}>
            <Tooltip
              title={
                <Box sx={{ whiteSpace: 'pre-wrap' }}>
                  {`Copy event data to clipboard\n${json}`}
                </Box>
              }
            >
              <IconButton
                size="small"
                sx={{ fontSize: '1rem' }}
                onClick={(e) => {
                  copyToClipboard(json);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }
    },
  ];

  useEffect(() => {
    if (props.orderEvent) {
      if (paramId !== -1) {
        const params = {OrderId: paramId};
  
        (
          inMemoryUser.isProduction() ?
          ajaxGetProductionOrders(params)
          :
          inMemoryUser.isShipping() ?
          ajaxGetShippingOrders(params)
          :
          ajaxGetOrders(params)
        )
          .then((res) => {
            const { data } = res;
            setOrder(data);
          })
          .catch(() => {
            setOrder({});
          });
      }
    } else if (props.quotationEvent) {
      if (paramId !== -1) {
        const params = {OrderId: paramId};
  
        (
          inMemoryUser.isProduction() ?
          ajaxGetProductionQuotations(params)
          :
          inMemoryUser.isShipping() ?
          ajaxGetShippingQuotations(params)
          :
          ajaxGetQuotations(params)
        )
          .then((res) => {
            const { data } = res;
            setOrder(data);
          })
          .catch(() => {
            setOrder({});
          });
      }
    } else if (props.packingListEvent) {
      if (paramId !== -1) {
        const params = {PackingListId: paramId};

        ajaxGetPackingLists(params)
          .then((res) => {
            const { data } = res;
            setPackingList(data);
          })
          .catch(() => {
            setPackingList({});
          });
      }
    }
  }, [paramId, props.orderEvent, props.quotationEvent, props.packingListEvent]);

  useEffect(() => {
    setProcessSearchTerm(debouncedSearchTerm);
    setPage(0)
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setLoading(true);
    (
      props.orderEvent || props.quotationEvent ?
      ajaxGetOrderEvents({orderId: paramId, limit: rowsPerPage, offset: page * rowsPerPage, search: processSearchTerm})
      :
      props.packingListEvent ?
      ajaxGetPackingListEvents({packingListId: paramId, limit: rowsPerPage, offset: page * rowsPerPage, search: processSearchTerm})
      :
      ajaxGetEvents({limit: rowsPerPage, offset: page * rowsPerPage, search: processSearchTerm})
    )
      .then((res) => {
        setItems(res.data);
        setTotal(res.total);
        setLoading(false);
      })
      .catch(() => {
        setItems([]);
        setLoading(false);
      });
  }, [props.orderEvent, props.quotationEvent, props.packingListEvent, paramId, page, rowsPerPage, processSearchTerm]);

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  const handleChangePage = (_, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <Page
      title={
        props.orderEvent ?
        [
          'Order History',
          ...(order ? [order.oc_ref] : [])
        ]
        : props.quotationEvent ? 
        [
          'Quotation History',
          ...(order ? [order.quote_ref] : [])
        ]
        : props.packingListEvent ?
        [
          'Packing List History',
          ...(packingList ? [packingList.oc_ref] : [])
        ] :
        'Events'
      }
      maxWidth={false}
      sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
      backButton={
        props.orderEvent || props.quotationEvent ?
        <IconButton
          color="primary"
          component={RouterLink}
          to={props.orderEvent ? `/orders/edit/${paramId}` : (props.quotationEvent ? `/quotations/edit/${paramId}` : `/packinglists/edit/${paramId}`)}
        >
          <ArrowBackIcon fontSize="medium" />
        </IconButton>
        :
        undefined
      }
    >
      <Box sx={{ mb: '5px' }}>
        <Grid xs={12} item>
          <TextField
            fullWidth
            size="small"
            type="text"
            label="Search"
            placeholder="Search keyword"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={searchChangeHandle}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        {loading && <LoadingSpinner />}
        <Box sx={{ position: 'absolute', inset: '0px 0px 10px 0px' }}>
          <EnhancedTable
            border
            fullHeight
            stickyColumn
            fontSize="12px"
            columns={columns}
            items={items}
            noPagination
          />
          <TablePagination
            component="div"
            count={total}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[25, 50, 100, 200]}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Page>
  );
}
