import React from 'react';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none'
});

export default function FileUploader({ accept, label, icon, handleChange, name, error, inputRef, disabled }) {
  const [isUploadedFile, setUploadedFile] = React.useState(false);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setUploadedFile(e.target.files[0]);
      handleChange(e);
    }
  };

  var buttonStyle = {
    fontSize: '12px'
  };

  if (error && !disabled) buttonStyle['color'] = 'red';

  let validateIcon = null;
  if ( isUploadedFile && !disabled ) {
    if ( error ) {
      validateIcon = <ErrorIcon sx={{ color: 'red', ml: '5px', mb: '5px' }} />
    } else {
      validateIcon = <CheckIcon sx={{ color: 'green', ml: '5px', mb: '5px' }} />
    }
  }

  return (
    <label htmlFor={name}>
      <Input ref={inputRef} disabled={disabled} accept={accept} id={name} name={name} multiple type="file" onChange={handleFileChange} />
      <Button disabled={disabled} startIcon={icon ? icon : ''} variant="text" component="span" style={buttonStyle}>
        {label} {validateIcon}
      </Button>
    </label>
  );
}

FileUploader.defaultProps = {
  disabled: false
};
