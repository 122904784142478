import Dashboard from '../pages/Dashboard/Dashboard';
import ProductionDashboard from '../pages/ProductionDashboard/ProductionDashboard';
import OrderList from '../pages/OrderList';
import Order from '../pages/Order';
import ShippingOrderList from '../pages/ShippingOrderList'
import ShippingOrder from '../pages/ShippingOrder'
import ProductionOrderList from '../pages/ProductionOrderList'
import ProductionOrder from '../pages/ProductionOrder'
import DealerList from '../pages/DealerList';
import Dealer from '../pages/Dealer';
import UserList from '../pages/UserList';
import User from '../pages/User';
import EventsList from '../pages/EventsList';
import ProductList from '../pages/Product/ProductList';
import Product from '../pages/Product/Product';
import Reports from '../pages/Reports/Reports';
import QuotationList from '../pages/Quotation/QuotationList';
import Quotation from '../pages/Quotation/Quotation';
import PackingListList from '../pages/PackingListList';
import PackingList from '../pages/PackingList';

const adminRoutes = [
  { path: '/dashboard', element: Dashboard, exact: true },
  { path: '/dashboard/:dealerId', element: Dashboard, exact: true },

  { path: '/products', element: ProductList, exact: true },
  { path: '/products/add', element: Product, elementProps: { add: true }, exact: true },
  { path: '/products/edit/:ProductId', element: Product, exact: true },

  { path: '/quotations', element: QuotationList, exact: true },
  { path: '/quotations/add', element: Quotation, elementProps: { add: true }, exact: true },
  { path: '/quotations/edit/:QuotationId', element: Quotation, exact: true },

  { path: '/orders', element: OrderList, exact: true },
  { path: '/orders/add', element: Order, elementProps: { add: true }, exact: true },
  { path: '/orders/edit/:OrderId', element: Order, exact: true },

  { path: '/dealers', element: DealerList, exact: true },
  { path: '/dealers/add', element: Dealer, elementProps: { add: true }, exact: true },
  { path: '/dealers/edit/:DealerId', element: Dealer, exact: true },

  { path: '/users', element: UserList, exact: true },
  { path: '/users/add', element: User, elementProps: { add: true }, exact: true },
  { path: '/users/edit/:UserId', element: User, exact: true },

  { path: '/events', element: EventsList, exact: true },
  { path: '/events/order/:ParamId', element: EventsList, elementProps: { orderEvent: true }, exact: true },
  { path: '/events/quotation/:ParamId', element: EventsList, elementProps: { quotationEvent: true }, exact: true },
  { path: '/events/packinglist/:ParamId', element: EventsList, elementProps: { packingListEvent: true }, exact: true },

  { path: '/reports', element: Reports, exact: true },

  { path: '/packinglists', element: PackingListList, exact: true },
  { path: '/packinglists/add', element: PackingList, elementProps: { add: true }, exact: true },
  { path: '/packinglists/edit/:PackingListId', element: PackingList, exact: true },
];

const saleRoutes = [
  { path: '/dashboard', element: Dashboard, exact: true },
  { path: '/dashboard/:dealerId', element: Dashboard, exact: true },

  { path: '/products', element: ProductList, exact: true },
  { path: '/products/add', element: Product, elementProps: { add: true }, exact: true },
  { path: '/products/edit/:ProductId', element: Product, exact: true },

  { path: '/quotations', element: QuotationList, exact: true },
  { path: '/quotations/add', element: Quotation, elementProps: { add: true }, exact: true },
  { path: '/quotations/edit/:QuotationId', element: Quotation, exact: true },

  { path: '/orders', element: OrderList, exact: true },
  { path: '/orders/add', element: Order, elementProps: { add: true }, exact: true },
  { path: '/orders/edit/:OrderId', element: Order, exact: true },

  { path: '/dealers', element: DealerList, exact: true },
  { path: '/dealers/add', element: Dealer, elementProps: { add: true }, exact: true },
  { path: '/dealers/edit/:DealerId', element: Dealer, exact: true },

  { path: '/events', element: EventsList, exact: true },
  { path: '/events/order/:ParamId', element: EventsList, elementProps: { orderEvent: true }, exact: true },
  { path: '/events/quotation/:ParamId', element: EventsList, elementProps: { quotationEvent: true }, exact: true },
  { path: '/events/packinglist/:ParamId', element: EventsList, elementProps: { packingListEvent: true }, exact: true },

  { path: '/reports', element: Reports, exact: true },

  { path: '/packinglists', element: PackingListList, exact: true },
  { path: '/packinglists/add', element: PackingList, elementProps: { add: true }, exact: true },
  { path: '/packinglists/edit/:PackingListId', element: PackingList, exact: true },
];

const shippingRoutes = [
  { path: '/products', element: ProductList, exact: true },
  { path: '/products/edit/:ProductId', element: Product, exact: true },

  { path: '/quotations', element: QuotationList, exact: true },
  { path: '/quotations/edit/:QuotationId', element: Quotation, exact: true },

  { path: '/orders', element: ShippingOrderList, exact: true },
  { path: '/orders/add', element: ShippingOrder, elementProps: { add: true }, exact: true },
  { path: '/orders/edit/:OrderId', element: ShippingOrder, exact: true },

  { path: '/dealers', element: DealerList, exact: true },
  { path: '/dealers/add', element: Dealer, elementProps: { add: true }, exact: true },
  { path: '/dealers/edit/:DealerId', element: Dealer, exact: true },

  { path: '/events', element: EventsList, exact: true },
  { path: '/events/order/:ParamId', element: EventsList, elementProps: { orderEvent: true }, exact: true },
  { path: '/events/packinglist/:ParamId', element: EventsList, elementProps: { packingListEvent: true }, exact: true },

  { path: '/reports', element: Reports, exact: true },

  { path: '/packinglists', element: PackingListList, exact: true },
  { path: '/packinglists/add', element: PackingList, elementProps: { add: true }, exact: true },
  { path: '/packinglists/edit/:PackingListId', element: PackingList, exact: true },
];

const productionRoutes = [
  { path: '/dashboard', element: ProductionDashboard, exact: true },
  { path: '/dashboard/:dealerId', element: ProductionDashboard, exact: true },

  { path: '/products', element: ProductList, exact: true },
  { path: '/products/add', element: Product, elementProps: { add: true }, exact: true },
  { path: '/products/edit/:ProductId', element: Product, exact: true },

  { path: '/quotations', element: QuotationList, exact: true },
  { path: '/quotations/add', element: Quotation, elementProps: { add: true }, exact: true },
  { path: '/quotations/edit/:QuotationId', element: Quotation, exact: true },

  { path: '/orders', element: ProductionOrderList, exact: true },
  { path: '/orders/add', element: ProductionOrder, elementProps: { add: true }, exact: true },
  { path: '/orders/edit/:OrderId', element: ProductionOrder, exact: true },

  { path: '/dealers', element: DealerList, exact: true },
  { path: '/dealers/edit/:DealerId', element: Dealer, exact: true },

  { path: '/events', element: EventsList, exact: true },
  { path: '/events/order/:ParamId', element: EventsList, elementProps: { orderEvent: true }, exact: true },
  { path: '/events/quotation/:ParamId', element: EventsList, elementProps: { quotationEvent: true }, exact: true },
  { path: '/events/packinglist/:ParamId', element: EventsList, elementProps: { packingListEvent: true }, exact: true },

  { path: '/packinglists', element: PackingListList, exact: true },
  { path: '/packinglists/add', element: PackingList, elementProps: { add: true }, exact: true },
  { path: '/packinglists/edit/:PackingListId', element: PackingList, exact: true },
];

export { adminRoutes, saleRoutes, shippingRoutes, productionRoutes};
