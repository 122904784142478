import Noty from 'noty';
import { ajaxDownloadFile } from '../services/fileService';
import { MoneyFormat } from '../components/MoneyFormat';
import inMemoryJwt from '../services/inMemoryJwtService';

export const getRoleUrl = (subpath) => {
  const tokenPayload = inMemoryJwt.getTokenPayload();
  const role = tokenPayload['user_role'];

  let rolePath = '';
  switch (role) {
    case 'administrator':
      rolePath = 'admin';
      break;
    case 'sale':
      rolePath = 'sale';
      break;
    case 'shipping':
      rolePath = 'shipping';
      break;
    case 'production':
      rolePath = 'production';
      break;
    default:
      rolePath = '';
  }
  return rolePath + '/' + subpath;
};

export const copyToClipboard = (value) => {
  const textArea = document.createElement('textarea');
  textArea.setAttribute('readonly', '');
  textArea.style.position = 'absolute';
  textArea.style.opacity = 0;
  textArea.value = value;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy to clipboard', err);
  }
  document.body.removeChild(textArea);
};

export const  isPhone = (value) => {
  if ( value === undefined ) {
    return false;
  }
  if ( value === '' ) {
    return false;
  }
  let patt = new RegExp('^(\\+?[0-9\-\(\)]+)$');
  return patt.test(value);
};

export const  isFloat = (value) => {
  if ( value === undefined ) {
    return false;
  }
  if ( value === '' ) {
    return false;
  }
  return !isNaN(Number(value))
};

export function strToNum(str) {
  return Number(str.replace(/\D/g, ''));
}

export function downloadFile({ params, fileName, fileExt, onError }) {
  return ajaxDownloadFile(params)
    .then(({ blob, response }) => {
      let headerFilename = '';
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(response.headers.get('Content-Disposition'));
      if (matches != null && matches[1]) {
        headerFilename = matches[1].replace(/['"]/g, '');
      }

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = headerFilename !== '' ? headerFilename : fileName + '.' + fileExt;
      a.click();
    })
    .catch((error) => {
      if ( onError ) {
        onError(error.message);
      }
      console.log(error.message);
    });
}

export function viewFile({ params, fileName, fileExt, onError }) {
  return ajaxDownloadFile(params)
    .then(({ blob, response }) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    })
    .catch((error) => {
      if ( onError ) {
        onError(error.message);
      }
      console.log(error.message);
    });
}

export const toDate = (unixTime, isUTCformat) => {
  return new Date((unixTime + (isUTCformat ? (new Date()).getTimezoneOffset() * 60 : 0) ) * 1000);
};

export const toDateText = (unixTime, isUTCformat) => {
  return toDate(unixTime, isUTCformat).toLocaleDateString();
};

export const toDateTimeText = (unixTime, isUTCformat) => {
  return toDate(unixTime, isUTCformat).toLocaleString();
};

/**
 * Format MAC Address into XX-XX-XX-XX-XX-XX
 * @param {string} mac
 * @return {string} formatted mac
 */
export const formatMacAddress = (mac, formatSymbol) => {
  if ( typeof mac === 'undefined' ) {
    return '';
  }
  if ( !isMacAddress(mac) ) {
    return '';
  }
  if ( typeof formatSymbol === 'undefined') {
    formatSymbol = '-';
  }

  let m = mac.toUpperCase();
  // remove : or -
  m = m.replace(/:/g, '');
  m = m.replace(/-/g, '');

  let v = m;
  let l = v.length;

  let maxLen = 12; // Length of mac string exclude ':' or '-'
  if (l === maxLen) {
    let v1;
    v1 = v;
    /* Insert symbol after ever 2 chars */
    let arrv1 = v1.match(/.{1,2}/g); // ["ab", "dc","a"]
    m = arrv1.join(formatSymbol);
  }

  return m;
};

export const isMacAddress = (value) => {
  let mac = '^(?:(?:[0-9A-Fa-f]{2}(?=([-:]|))(?:\\1[0-9A-Fa-f]{2}){5}))$';
  return value.toString().match(mac);
}

export const PAYMENT_TERMS = [
  'None',
  '100% advance',
  '100% 30 days',
  '100% 45 days',
  '100% 60 days',
  '100% 90 days',
  '50% advance / NET 30',
  '50% advance / NET 60',
  '50% advance / NET 90',
  '35% advance / NET 30',
  '25% advance / NET 30',
  '25% advance / NET 60',
  '25% advance / NET 90'
];

export const countries = [
  {'name': 'Afghanistan', 'code': 'AF'},
  {'name': 'Åland Islands', 'code': 'AX'},
  {'name': 'Albania', 'code': 'AL'},
  {'name': 'Algeria', 'code': 'DZ'},
  {'name': 'American Samoa', 'code': 'AS'},
  {'name': 'AndorrA', 'code': 'AD'},
  {'name': 'Angola', 'code': 'AO'},
  {'name': 'Anguilla', 'code': 'AI'},
  {'name': 'Antarctica', 'code': 'AQ'},
  {'name': 'Antigua and Barbuda', 'code': 'AG'},
  {'name': 'Argentina', 'code': 'AR'},
  {'name': 'Armenia', 'code': 'AM'},
  {'name': 'Aruba', 'code': 'AW'},
  {'name': 'Australia', 'code': 'AU'},
  {'name': 'Austria', 'code': 'AT'},
  {'name': 'Azerbaijan', 'code': 'AZ'},
  {'name': 'Bahamas', 'code': 'BS'},
  {'name': 'Bahrain', 'code': 'BH'},
  {'name': 'Bangladesh', 'code': 'BD'},
  {'name': 'Barbados', 'code': 'BB'},
  {'name': 'Belarus', 'code': 'BY'},
  {'name': 'Belgium', 'code': 'BE'},
  {'name': 'Belize', 'code': 'BZ'},
  {'name': 'Benin', 'code': 'BJ'},
  {'name': 'Bermuda', 'code': 'BM'},
  {'name': 'Bhutan', 'code': 'BT'},
  {'name': 'Bolivia', 'code': 'BO'},
  {'name': 'Bosnia and Herzegovina', 'code': 'BA'},
  {'name': 'Botswana', 'code': 'BW'},
  {'name': 'Bouvet Island', 'code': 'BV'},
  {'name': 'Brazil', 'code': 'BR'},
  {'name': 'British Indian Ocean Territory', 'code': 'IO'},
  {'name': 'Brunei Darussalam', 'code': 'BN'},
  {'name': 'Bulgaria', 'code': 'BG'},
  {'name': 'Burkina Faso', 'code': 'BF'},
  {'name': 'Burundi', 'code': 'BI'},
  {'name': 'Cambodia', 'code': 'KH'},
  {'name': 'Cameroon', 'code': 'CM'},
  {'name': 'Canada', 'code': 'CA'},
  {'name': 'Cape Verde', 'code': 'CV'},
  {'name': 'Cayman Islands', 'code': 'KY'},
  {'name': 'Central African Republic', 'code': 'CF'},
  {'name': 'Chad', 'code': 'TD'},
  {'name': 'Chile', 'code': 'CL'},
  {'name': 'China', 'code': 'CN'},
  {'name': 'Christmas Island', 'code': 'CX'},
  {'name': 'Cocos (Keeling) Islands', 'code': 'CC'},
  {'name': 'Colombia', 'code': 'CO'},
  {'name': 'Comoros', 'code': 'KM'},
  {'name': 'Congo', 'code': 'CG'},
  {'name': 'Congo, The Democratic Republic of the', 'code': 'CD'},
  {'name': 'Cook Islands', 'code': 'CK'},
  {'name': 'Costa Rica', 'code': 'CR'},
  {'name': 'Cote D\'Ivoire', 'code': 'CI'},
  {'name': 'Croatia', 'code': 'HR'},
  {'name': 'Cuba', 'code': 'CU'},
  {'name': 'Cyprus', 'code': 'CY'},
  {'name': 'Czech Republic', 'code': 'CZ'},
  {'name': 'Denmark', 'code': 'DK'},
  {'name': 'Djibouti', 'code': 'DJ'},
  {'name': 'Dominica', 'code': 'DM'},
  {'name': 'Dominican Republic', 'code': 'DO'},
  {'name': 'Ecuador', 'code': 'EC'},
  {'name': 'Egypt', 'code': 'EG'},
  {'name': 'El Salvador', 'code': 'SV'},
  {'name': 'Equatorial Guinea', 'code': 'GQ'},
  {'name': 'Eritrea', 'code': 'ER'},
  {'name': 'Estonia', 'code': 'EE'},
  {'name': 'Ethiopia', 'code': 'ET'},
  {'name': 'Falkland Islands (Malvinas)', 'code': 'FK'},
  {'name': 'Faroe Islands', 'code': 'FO'},
  {'name': 'Fiji', 'code': 'FJ'},
  {'name': 'Finland', 'code': 'FI'},
  {'name': 'France', 'code': 'FR'},
  {'name': 'French Guiana', 'code': 'GF'},
  {'name': 'French Polynesia', 'code': 'PF'},
  {'name': 'French Southern Territories', 'code': 'TF'},
  {'name': 'Gabon', 'code': 'GA'},
  {'name': 'Gambia', 'code': 'GM'},
  {'name': 'Georgia', 'code': 'GE'},
  {'name': 'Germany', 'code': 'DE'},
  {'name': 'Ghana', 'code': 'GH'},
  {'name': 'Gibraltar', 'code': 'GI'},
  {'name': 'Greece', 'code': 'GR'},
  {'name': 'Greenland', 'code': 'GL'},
  {'name': 'Grenada', 'code': 'GD'},
  {'name': 'Guadeloupe', 'code': 'GP'},
  {'name': 'Guam', 'code': 'GU'},
  {'name': 'Guatemala', 'code': 'GT'},
  {'name': 'Guernsey', 'code': 'GG'},
  {'name': 'Guinea', 'code': 'GN'},
  {'name': 'Guinea-Bissau', 'code': 'GW'},
  {'name': 'Guyana', 'code': 'GY'},
  {'name': 'Haiti', 'code': 'HT'},
  {'name': 'Heard Island and Mcdonald Islands', 'code': 'HM'},
  {'name': 'Holy See (Vatican City State)', 'code': 'VA'},
  {'name': 'Honduras', 'code': 'HN'},
  {'name': 'Hong Kong', 'code': 'HK'},
  {'name': 'Hungary', 'code': 'HU'},
  {'name': 'Iceland', 'code': 'IS'},
  {'name': 'India', 'code': 'IN'},
  {'name': 'Indonesia', 'code': 'ID'},
  {'name': 'Iran, Islamic Republic Of', 'code': 'IR'},
  {'name': 'Iraq', 'code': 'IQ'},
  {'name': 'Ireland', 'code': 'IE'},
  {'name': 'Isle of Man', 'code': 'IM'},
  {'name': 'Israel', 'code': 'IL'},
  {'name': 'Italy', 'code': 'IT'},
  {'name': 'Jamaica', 'code': 'JM'},
  {'name': 'Japan', 'code': 'JP'},
  {'name': 'Jersey', 'code': 'JE'},
  {'name': 'Jordan', 'code': 'JO'},
  {'name': 'Kazakhstan', 'code': 'KZ'},
  {'name': 'Kenya', 'code': 'KE'},
  {'name': 'Kiribati', 'code': 'KI'},
  {'name': 'Korea, Democratic People\'s Republic of', 'code': 'KP'},
  {'name': 'Korea, Republic of', 'code': 'KR'},
  {'name': 'Kuwait', 'code': 'KW'},
  {'name': 'Kyrgyzstan', 'code': 'KG'},
  {'name': 'Lao People\'s Democratic Republic', 'code': 'LA'},
  {'name': 'Latvia', 'code': 'LV'},
  {'name': 'Lebanon', 'code': 'LB'},
  {'name': 'Lesotho', 'code': 'LS'},
  {'name': 'Liberia', 'code': 'LR'},
  {'name': 'Libyan Arab Jamahiriya', 'code': 'LY'},
  {'name': 'Liechtenstein', 'code': 'LI'},
  {'name': 'Lithuania', 'code': 'LT'},
  {'name': 'Luxembourg', 'code': 'LU'},
  {'name': 'Macao', 'code': 'MO'},
  {'name': 'Macedonia, The Former Yugoslav Republic of', 'code': 'MK'},
  {'name': 'Madagascar', 'code': 'MG'},
  {'name': 'Malawi', 'code': 'MW'},
  {'name': 'Malaysia', 'code': 'MY'},
  {'name': 'Maldives', 'code': 'MV'},
  {'name': 'Mali', 'code': 'ML'},
  {'name': 'Malta', 'code': 'MT'},
  {'name': 'Marshall Islands', 'code': 'MH'},
  {'name': 'Martinique', 'code': 'MQ'},
  {'name': 'Mauritania', 'code': 'MR'},
  {'name': 'Mauritius', 'code': 'MU'},
  {'name': 'Mayotte', 'code': 'YT'},
  {'name': 'Mexico', 'code': 'MX'},
  {'name': 'Micronesia, Federated States of', 'code': 'FM'},
  {'name': 'Moldova, Republic of', 'code': 'MD'},
  {'name': 'Monaco', 'code': 'MC'},
  {'name': 'Mongolia', 'code': 'MN'},
  {'name': 'Montenegro', 'code': 'ME'},
  {'name': 'Montserrat', 'code': 'MS'},
  {'name': 'Morocco', 'code': 'MA'},
  {'name': 'Mozambique', 'code': 'MZ'},
  {'name': 'Myanmar', 'code': 'MM'},
  {'name': 'Namibia', 'code': 'NA'},
  {'name': 'Nauru', 'code': 'NR'},
  {'name': 'Nepal', 'code': 'NP'},
  {'name': 'Netherlands', 'code': 'NL'},
  {'name': 'Netherlands Antilles', 'code': 'AN'},
  {'name': 'New Caledonia', 'code': 'NC'},
  {'name': 'New Zealand', 'code': 'NZ'},
  {'name': 'Nicaragua', 'code': 'NI'},
  {'name': 'Niger', 'code': 'NE'},
  {'name': 'Nigeria', 'code': 'NG'},
  {'name': 'Niue', 'code': 'NU'},
  {'name': 'Norfolk Island', 'code': 'NF'},
  {'name': 'Northern Mariana Islands', 'code': 'MP'},
  {'name': 'Norway', 'code': 'NO'},
  {'name': 'Oman', 'code': 'OM'},
  {'name': 'Pakistan', 'code': 'PK'},
  {'name': 'Palau', 'code': 'PW'},
  {'name': 'Palestinian Territory, Occupied', 'code': 'PS'},
  {'name': 'Panama', 'code': 'PA'},
  {'name': 'Papua New Guinea', 'code': 'PG'},
  {'name': 'Paraguay', 'code': 'PY'},
  {'name': 'Peru', 'code': 'PE'},
  {'name': 'Philippines', 'code': 'PH'},
  {'name': 'Pitcairn', 'code': 'PN'},
  {'name': 'Poland', 'code': 'PL'},
  {'name': 'Portugal', 'code': 'PT'},
  {'name': 'Puerto Rico', 'code': 'PR'},
  {'name': 'Qatar', 'code': 'QA'},
  {'name': 'Reunion', 'code': 'RE'},
  {'name': 'Romania', 'code': 'RO'},
  {'name': 'Russian Federation', 'code': 'RU'},
  {'name': 'RWANDA', 'code': 'RW'},
  {'name': 'Saint Helena', 'code': 'SH'},
  {'name': 'Saint Kitts and Nevis', 'code': 'KN'},
  {'name': 'Saint Lucia', 'code': 'LC'},
  {'name': 'Saint Pierre and Miquelon', 'code': 'PM'},
  {'name': 'Saint Vincent and the Grenadines', 'code': 'VC'},
  {'name': 'Samoa', 'code': 'WS'},
  {'name': 'San Marino', 'code': 'SM'},
  {'name': 'Sao Tome and Principe', 'code': 'ST'},
  {'name': 'Saudi Arabia', 'code': 'SA'},
  {'name': 'Senegal', 'code': 'SN'},
  {'name': 'Serbia', 'code': 'RS'},
  {'name': 'Seychelles', 'code': 'SC'},
  {'name': 'Sierra Leone', 'code': 'SL'},
  {'name': 'Singapore', 'code': 'SG'},
  {'name': 'Slovakia', 'code': 'SK'},
  {'name': 'Slovenia', 'code': 'SI'},
  {'name': 'Solomon Islands', 'code': 'SB'},
  {'name': 'Somalia', 'code': 'SO'},
  {'name': 'South Africa', 'code': 'ZA'},
  {'name': 'South Georgia and the South Sandwich Islands', 'code': 'GS'},
  {'name': 'Spain', 'code': 'ES'},
  {'name': 'Sri Lanka', 'code': 'LK'},
  {'name': 'Sudan', 'code': 'SD'},
  {'name': 'Suriname', 'code': 'SR'},
  {'name': 'Svalbard and Jan Mayen', 'code': 'SJ'},
  {'name': 'Swaziland', 'code': 'SZ'},
  {'name': 'Sweden', 'code': 'SE'},
  {'name': 'Switzerland', 'code': 'CH'},
  {'name': 'Syrian Arab Republic', 'code': 'SY'},
  {'name': 'Taiwan, Province of China', 'code': 'TW'},
  {'name': 'Tajikistan', 'code': 'TJ'},
  {'name': 'Tanzania, United Republic of', 'code': 'TZ'},
  {'name': 'Thailand', 'code': 'TH'},
  {'name': 'Timor-Leste', 'code': 'TL'},
  {'name': 'Togo', 'code': 'TG'},
  {'name': 'Tokelau', 'code': 'TK'},
  {'name': 'Tonga', 'code': 'TO'},
  {'name': 'Trinidad and Tobago', 'code': 'TT'},
  {'name': 'Tunisia', 'code': 'TN'},
  {'name': 'Turkey', 'code': 'TR'},
  {'name': 'Turkmenistan', 'code': 'TM'},
  {'name': 'Turks and Caicos Islands', 'code': 'TC'},
  {'name': 'Tuvalu', 'code': 'TV'},
  {'name': 'Uganda', 'code': 'UG'},
  {'name': 'Ukraine', 'code': 'UA'},
  {'name': 'United Arab Emirates', 'code': 'AE'},
  {'name': 'United Kingdom', 'code': 'GB'},
  {'name': 'United States', 'code': 'US'},
  {'name': 'United States Minor Outlying Islands', 'code': 'UM'},
  {'name': 'Uruguay', 'code': 'UY'},
  {'name': 'Uzbekistan', 'code': 'UZ'},
  {'name': 'Vanuatu', 'code': 'VU'},
  {'name': 'Venezuela', 'code': 'VE'},
  {'name': 'Viet Nam', 'code': 'VN'},
  {'name': 'Virgin Islands, British', 'code': 'VG'},
  {'name': 'Virgin Islands, U.S.', 'code': 'VI'},
  {'name': 'Wallis and Futuna', 'code': 'WF'},
  {'name': 'Western Sahara', 'code': 'EH'},
  {'name': 'Yemen', 'code': 'YE'},
  {'name': 'Zambia', 'code': 'ZM'},
  {'name': 'Zimbabwe', 'code': 'ZW'}
];

Noty.overrideDefaults({
  theme: 'relax',
  callbacks: {
    onTemplate: function() {
      this.layoutDom.style.top = '60px';
      this.barDom.style.padding = '5px';
      switch (this.options.type) {
        case 'warning':
          this.barDom.style.backgroundColor = '#FFEAA8';
          this.barDom.style.borderColor = '#FFC237';
          this.barDom.style.color = '#826200';
          break;
        case 'error':
        case 'alert':
          this.barDom.style.backgroundColor = '#f2dede';
          this.barDom.style.borderColor = '#ebccd1';
          this.barDom.style.color = '#a94442';
          break;
        case 'success':
          this.barDom.style.backgroundColor = '#BCF5BC';
          this.barDom.style.borderColor = '#7cdd77';
          this.barDom.style.color = 'darkgreen';
          break;
        default:
          this.barDom.style.backgroundColor = '#d9edf7';
          this.barDom.style.borderColor = '#bce8f1';
          this.barDom.style.color = '#31708f';
          break;
      }
    }
  }
});

export function noty( msg, type, onClose ) {
  let text = msg
  if ( type === 'success' ) {
    text = `
      <div style="display: flex; align-items: center;">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          data-testid="CheckIcon"
          style="
            user-select: none;
            width: 24px;
            height: 24px;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            font-size: inherit;
            margin-right: 10px;
        ">
          <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
        </svg>
        ${msg}
      </div>
    `;
  }
  else if ( type === 'warning' ) {
    text = `
      <div style="display: flex; align-items: center;">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          data-testid="WarningAmberIcon"
          style="
            user-select: none;
            width: 24px;
            height: 24px;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            font-size: inherit;
            margin-right: 10px;
        ">
          <path d="M12 5.99 19.53 19H4.47L12 5.99M12 2 1 21h22L12 2z"></path>
          <path d="M13 16h-2v2h2zm0-6h-2v5h2z"></path>
        </svg>
        ${msg}
      </div>
    `;
  }
  else if ( type === 'error' ) {
    text = `
      <div style="display: flex; align-items: center;">
        <svg
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          data-testid="ErrorOutlineIcon"
          style="
            user-select: none;
            width: 24px;
            height: 24px;
            display: inline-block;
            fill: currentcolor;
            flex-shrink: 0;
            font-size: inherit;
            margin-right: 10px;
        ">
          <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
        </svg>
        ${msg}
      </div>
    `;
  }
  let n = new Noty({
    text: text,
    layout: 'topCenter',
    type: type,
    buttons: false,
    closeWith: ['click'],
    callbacks: {
      onClose: onClose
    }
  });
  n.show();
  return n;
}

export const priceFormat = (v) => isFloat(v) ? <MoneyFormat currency="$" value={v}/> : '';

export const calcTotalPrice = (p) => p.quantity * p.msrp * (100 - (p.discount || 0)) / 100;