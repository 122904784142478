import NumberFormat from 'react-number-format';
import {getParamByParam} from 'iso-country-currency';

export function MoneyFormat(props) {
  let symbol = getParamByParam('currency', 'USD', 'symbol');;
  if ( props.currency ) {
    try {
      symbol = getParamByParam('currency', props.currency, 'symbol');
    }
    catch(e) {
    }
  }

  if ( props.currencyPrefix ) {
    symbol = `${props.currencyPrefix}${symbol}`;
  }
  return (
    <NumberFormat
      isNumericString
      thousandSeparator=","
      displayType={'text'}
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      prefix={symbol ? `${symbol} ` : undefined}
      {...props}
    />
  );
}
