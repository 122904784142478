import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import UtcDatePicker from '../components/UtcDatePicker';
import { useNavigate, useParams } from 'react-router-dom';
import { ajaxGetOrders, ajaxSaveOrder } from '../services/productionOrderService';
import { ajaxGetDealersAutocomplete } from '../services/dealerService';
import validator from 'validator';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';
import FileUploader from '../components/FileUploader';
import OrderProducts from '../components/OrderProducts';
import ConfirmationButton from '../components/ConfirmationButton';
import moment from 'moment';
import { toDate, isFloat, calcTotalPrice, countries, noty } from '../helpers';


export default function ProductionOrder(props) {
  const incomingParams = useParams();
  const [searchParams] = useSearchParams();

  const [order, setOrder] = useState({
    shipping_status: 'wait',
    products: [],
    date: moment().hour(0).minute(0).second(0).millisecond(0).unix() + (moment().utcOffset() * 60)
  });

  const [dealers, setDealers] = useState([]);
  const [dealerChanged, setDealerChanged] = useState(false);
  const [productChanged, setProductChanged] = useState(false);
  const [backUrl, setBackUrl] = useState('/orders');

  useEffect(() => {
    if(searchParams.get("page") === 'dashboard') {
      setBackUrl('/dashboard')  
    } else {
      setBackUrl('/orders') 
    }
  }, [searchParams]);

  const allFields = useMemo(
    () => {
      const updateOrderProducts = (orderProducts) => {
        setOrder(order=> ({...order, products: orderProducts}));
        setProductChanged(true);
      };
      const dealer = (dealers || []).find(d => d.id === order.dealer)
      return [
        {
          key: 'dealer',
          type: 'autocomplete',
          label: 'Company',
          dataset: dealers.map(d=>({value:d.id, label:d.name})),
          gridProps: { xs: 12 },
          disabled: true
        },
        {
          key: 'billing_country',
          type: 'list',
          label: 'Billing Country',
          dataset: countries.map(c => ({ value: c.name, label: c.name })),
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_country',
          type: 'list',
          label: 'Shipping Country',
          dataset: countries.map(c => ({ value: c.name, label: c.name })),
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_company',
          type: 'autocomplete',
          label: 'Billing Company',
          dataset: dealers.map(d=>({value:d.id, label:d.name})),
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_company',
          type: 'autocomplete',
          label: 'Shipping Company',
          dataset: dealers.map(d=>({value:d.id, label:d.name})),
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_address',
          type: 'text',
          label: 'Billing Address',
          gridProps: { xs: 6 },
          multiline: true
        },
        {
          key: 'shipping_address',
          type: 'text',
          label: 'Shipping Address',
          gridProps: { xs: 6 },
          multiline: true
        },
        {
          key: 'billing_vat',
          type: 'text',
          label: 'Billing VAT',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_vat',
          type: 'text',
          label: 'Shipping VAT',
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_email',
          type: 'text',
          label: 'Billing Email',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_email',
          type: 'text',
          label: 'Shipping Email',
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_phone',
          type: 'text',
          label: 'Billing Phone Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_phone',
          type: 'text',
          label: 'Shipping Phone Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'billing_mobile',
          type: 'text',
          label: 'Billing Mobile Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_mobile',
          type: 'text',
          label: 'Shipping Mobile Number',
          gridProps: { xs: 6 }
        },
        {
          key: 'customer_ref',
          type: 'text',
          label: 'Customer Reference',
          gridProps: { xs: 6 },
          divider: true
        },
        {
          key: 'products',
          type: 'component',
          component: OrderProducts,
          componentProps: {
            dealerDiscount: dealer ? dealer.discount || 0 : 0,
            dealerChanged: dealerChanged,
            orderProducts: order.products,
            updateOrderProducts: updateOrderProducts
          }
        },
        {
          key: 'invoice_note',
          type: 'text',
          label: 'OC/Invoice Note',
          divider: true,
          multiline: true,
          lineNum: 15
        },
        {
          key: 'date',
          type: 'date',
          label: 'Order Date',
          gridProps: { xs: 12, sm: 6 },
          disabled: true
        },
        {
          key: 'value',
          type: 'dollar',
          label: 'Order Value',
          gridProps: { xs: 12, sm: 6 },
          disabled: true
        },
        {
          key: 'quote_ref',
          type: 'text',
          label: 'Quotation Ref.',
          gridProps: { xs: 12, sm: 4 },
          disabled: true
        },
        {
          key: 'oc_ref',
          type: 'text',
          label: 'OC Ref.',
          gridProps: { xs: 12, sm: 4 },
          disabled: true
        },
        {
          key: 'invoice',
          type: 'text',
          label: 'Invoice No.',
          gridProps: { xs: 12, sm: 4 },
          disabled: true
        },
        {
          key: 'note',
          type: 'text',
          label: 'Note',
          divider: true,
          multiline: true
        },
        {
          key: 'shipping_for_today',
          type: 'checkbox',
          label: 'Shipments for Today',
          disabled: order.shipping_status === 'done',
          gridProps: { xs: 6 }
        },
        {
          key: 'distributor_shipments',
          type: 'checkbox',
          label: 'Distributor Shipments',
          disabled: order.shipping_status === 'done',
          gridProps: { xs: 6 }
        },
        {
          key: 'shipping_date_expected',
          type: 'date',
          label: 'Expected Shipping Date',
          gridProps: { xs: 6 },
          disabled: true
        },
        {
          key: 'shipping_date',
          type: 'date',
          label: 'Shipping Date',
          gridProps: { xs: 6 },
          disabled: true
        },
        {
          key: 'shipping_status',
          type: 'list',
          label: 'Shipping',
          dataset: [{value:'wait', label:'Wait'}, {value:'ship', label:'Ship'}, {value:'done', label:'Done'}, {value:'cancel', label:'Cancel'}],
          disabled: true
        },
        {
          key: 'shipping_cost',
          type: 'text',
          label: 'Shipping Cost',
          disabled: true,
          gridProps: { xs: 12, sm: 4 }
        },
        {
          key: 'actual_shipping_cost',
          type: 'text',
          label: 'Actual Shipping Cost',
          disabled: true,
          gridProps: { xs: 12, sm: 4 }
        },
        {
          key: 'shipping_weight',
          type: 'text',
          label: 'Shipping Weight',
          disabled: true,
          gridProps: { xs: 12, sm: 4 }
        },
        {
          key: 'shipping_awb',
          type: 'text',
          label: 'AWB',
          gridProps: { xs: 12, sm: 8 },
          disabled: true
        },
        {
          key: 'shipping_invoice_pdf',
          type: 'file',
          label: 'Shipping Invoice',
          gridProps: { xs: 6, sm: 2 },
          disabled: true
        },
        {
          key: 'shipping_packing_list_pdf',
          type: 'file',
          label: 'Packing List',
          gridProps: { xs: 6, sm: 2 }
        }
      ];
    },
    [ dealers, order.shipping_status, order.dealer, order.products, dealerChanged ]
  );
  

  const requiredFields = useMemo(() => ['dealer', 'date', 'oc_ref', 'oc_pdf'], []);

  const emailFields = useMemo(() => ['email'], []);

  const floatFields = useMemo(() => ['shipping_cost', 'actual_shipping_cost'], []);

  const [errors, setErrors] = useState({});
  const [duplicateErrors, setDuplicateErrors] = useState({});
  const [submitOnce, setSubmitOnce] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();

  const paramOrderId = (incomingParams && incomingParams.OrderId ? incomingParams.OrderId : -1);

  useEffect(() => {
    ajaxGetDealersAutocomplete({})
      .then((res) => {
        const { data } = res;
        setDealers(data);
      })
      .catch(() => {
        setDealers([]);
      });
  }, []);

  useEffect(() => {
    if (!props.add) {
      if (paramOrderId !== -1) {
        const params = {OrderId: paramOrderId};
  
        ajaxGetOrders(params)
          .then((res) => {
            const { data } = res;
            setOrder(data);
          })
          .catch(() => {
            setOrder({});
          });
      }
    }
  }, [paramOrderId, props.add]);

  const dealerId = order ? order.dealer : null;
  useEffect(() => {
    const dealer = (dealers || []).find(d => d.id === dealerId)
    if(dealerChanged && dealer) {
      setOrder(order=>({
        ...order,
        billing_country: dealer.country || '',
        billing_company: dealer.id || '',
        billing_address: dealer.billing_address,
        billing_vat: dealer.billing_vat,
        billing_email: dealer.billing_email,
        billing_phone: dealer.billing_phone,
        billing_mobile: dealer.billing_mobile,
        shipping_country: dealer.country || '',
        shipping_company: dealer.id || '',
        shipping_address: dealer.same_address ? dealer.billing_address : dealer.shipping_address,
        shipping_vat: dealer.same_address ? dealer.billing_vat : dealer.shipping_vat,
        shipping_email: dealer.same_address ? dealer.billing_email : dealer.shipping_email,
        shipping_phone: dealer.same_address ? dealer.billing_phone : dealer.shipping_phone,
        shipping_mobile:dealer.same_address ? dealer.billing_mobile :  dealer.shipping_mobile,
        invoice_note: dealer.order_notes
      }));
    }
  }, [dealers, dealerId, dealerChanged]);

  useEffect(() => {
    if(productChanged) {
      setOrder(order=>({
        ...order,
        value: order.products.reduce((acc, p) => acc + calcTotalPrice(p), 0),
        shipping_cost: order.products.reduce((acc, p) => acc + (p.is_shipping_cost ? calcTotalPrice(p) : 0), 0)
      }));
    }
  }, [productChanged, order.products]);

  const validateFields = useCallback(() => {
    const fields = { ...order };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && emailFields.includes(key) && !validator.isEmail(fieldValue)) {
        newErros[key] = 'Please enter a valid email address';
        result = false;
      } else if (fieldValue && floatFields.includes(key) && !isFloat(fieldValue)) {
        newErros[key] = 'Please enter a valid number';
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [order, allFields, requiredFields, emailFields, floatFields]);

  useEffect(() => {
    if (!props.add) {
      validateFields();
    } else if (props.add && submitOnce) {
      validateFields();
    }
  }, [validateFields, props.add, submitOnce]);

  useEffect(() => {
    if ( duplicateErrors.oc_ref ) {
      setDuplicateErrors({
        ...duplicateErrors,
        oc_ref : null
      });
    }
  }, [order.oc_ref]);

  useEffect(() => {
    if ( duplicateErrors.invoice ) {
      setDuplicateErrors({
        ...duplicateErrors,
        invoice : null
      });
    }
  }, [order.invoice]);

  const handleSave = (revise, showNoty = true) => () => {
    setSubmitOnce(true);
    setIsSaving(true);
    if (!validateFields()) return false;
    if(Object.keys(duplicateErrors).filter(k => !!duplicateErrors[k]).length > 0) {
      return false;
    }

    const params = { ...order, ...!!revise ? {revise: true} : {} };
    ajaxSaveOrder(params)
    .then((res) => {
      if(showNoty){
        if (res.error) {
          noty(res.error, 'error');
        } else {
          noty('Save successfully', 'success');
        }
      }
      setOrder(res.data);
      setIsSaving(false)
    })
      .catch((e) => {
        if ( e.cause ) {
          let errorResult = {};
          for ( let k in e.cause ) {
            if ( allFields.filter(f => f.key === k).length > 0 ) {
              errorResult[k] = e.cause[k];
            }
          }
          setDuplicateErrors(errorResult);
        }
        setIsSaving(false);
        console.log(e.message)
      });
  };

  const handleDuplicate = (revise) => () => {
    setSubmitOnce(true);
    if (!validateFields()) return false;
    if(Object.keys(duplicateErrors).filter(k => !!duplicateErrors[k]).length > 0) {
      return false;
    }

    const params = { ...order, ...!!revise ? {revise: true} : {} };

    delete params.id;
    delete params.invoice;
    delete params.oc_ref;

    ajaxSaveOrder(params)
      .then((res) => navigate(backUrl))
      .catch((e) => {
        if ( e.cause ) {
          let errorResult = {};
          for ( let k in e.cause ) {
            if ( allFields.filter(f => f.key === k).length > 0 ) {
              errorResult[k] = e.cause[k];
            }
          }
          setDuplicateErrors(errorResult);
        }
        console.log(e.message)
      });
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
        key = typeof event === 'string' ? event : event.target.name,
        field = allFields.find(f=>f.key === key);
    
    if ( key === 'dealer' &&  value !==null && order && order.dealer !== value ) {
      setDealerChanged(true);
    }
    
    if ( field && field.type === 'autocomplete') {
      setOrder({ ...order, [key]: value ? value.value : null });
    } else if ( key === 'shipping_status' && value === 'done' ) {
      setOrder({
        ...order,
        shipping_status: value,
        shipping_for_today: 0
      });
    }
    else if ( key === 'shipping_awb' && !!value ) {
      setOrder({
        ...order,
        shipping_awb: value,
        shipping_status: 'done',
        shipping_for_today: 0
      });
    }
    else {
      setOrder({ ...order, [key]: value });
    }
  };

  const handleUploadFile = (e) => {
    let key = e.target.name;
    let value = e.target.files[0];
    let filename = value.name.replace('.pdf', '');
    let newOrder = {
      ...order,
      [key]: value
    };
    if ( key === 'oc_pdf' ) {
      newOrder = {
        ...newOrder,
        oc_ref: filename
      };
    }
    else if ( key === 'invoice_pdf' ) {
      newOrder = {
        ...newOrder,
        invoice: filename
      };
    }
    setOrder(newOrder);
  };

  const getFieldValue = (key) => order && order[key] !== undefined && order[key] !== null ? order[key] : '';

  const getFieldPlaceholder = (key) => key === 'password' && !props.add ? 'leave blank to not change the password' : '';

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : (duplicateErrors && duplicateErrors[key] ? duplicateErrors[key] : '');

  const checkFieldError = (key) => (errors && errors[key]) || (duplicateErrors && duplicateErrors[key]);

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  return (
    <Page title="Production Edit Order">
      <StyledCard>
        <StyledCardFormContent>
          {
            !props.add &&
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '25px', mt: '-20px' }}>
              <Link to={`/events/order/${order.id}`}>
                View History
              </Link>
            </Box>
          }
          <StyledMainGrid columnSpacing alignItems="center" >
            {allFields.map((entry, index) => (
              <React.Fragment key={entry['key']}>
                { (entry['type'] === 'component') && 
                  <Grid item xs={12} {...entry['gridProps']}>
                    <entry.component {...entry.componentProps} beforeRedirect={handleSave(false, false)}/>
                  </Grid>
                }
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      size="small"
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      {...entry.multiline ? { multiline: true, rows: entry.lineNum ? entry.lineNum : 4 } : {}}
                    />
                  </Grid>
                }
                {
                  (entry['type'] === 'dollar') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      type={entry['type']}
                      name={entry['key']}
                      size="small"
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                      {...entry.multiline ? { multiline: true, rows: 4 } : {}}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">US$</InputAdornment>,
                      }}
                    />
                  </Grid>
                }
                {
                  (entry['type'] === 'date') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <UtcDatePicker
                      label={entry['label']}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          error: checkFieldError(entry['key']),
                          helperText: getFieldErrorText(entry['key']),
                          disabled: !!entry.disabled
                        },
                      }}
                      value={getFieldValue(entry['key']) ? moment(toDate(getFieldValue(entry['key']))).unix() : null}
                      onChange={(value) => handleFieldChange(entry['key'], value)}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'autocomplete' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <Autocomplete
                        sx={{ width: '100%' }}
                        size="small" 
                        value={(entry.dataset || []).find(o=>o.value === getFieldValue(entry['key'])) || null}
                        options={(entry.dataset || [])}
                        isOptionEqualToValue={(option, value) => value && option.value === value.value}
                        getOptionLabel={(option) => `${option.label} [${option.value}]`}
                        onChange={(_,v)=>handleFieldChange(entry['key'], v)}
                        renderInput={(params) => <TextField {...params} label={entry['label']}/>}
                      />
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'list'&&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        size="small"
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        sx={{ width: '100%' }}
                      >
                        { (entry.dataset || []).map( c =>
                          <MenuItem value={c.value}>{c.label}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'checkbox' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControlLabel
                      control={<Checkbox name={entry['key']} value={1}/>}
                      onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                      checked={!!parseInt(getFieldValue(entry['key'], 10))}
                      label={entry['label']}
                      disabled={!!entry.disabled}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'file' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <FileUploader
                        error={checkFieldError(entry['key'])}
                        accept=".pdf"
                        name={entry['key']}
                        label={entry['label']}
                        icon={<AttachFileIcon />}
                        handleChange={handleUploadFile}
                        disabled={!!entry.disabled}
                      />
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['divider'] && 
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                }
              </React.Fragment>
            ))}
            <Grid item xs={12} />
            <Grid item xs={12} sm={8}>
              <Button disabled={isSaving} variant="contained" size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave(false)}>
                Save Order
              </Button>
              {
                paramOrderId !== -1 && 
                <ConfirmationButton
                  buttonProps={{
                    disabled: isSaving,
                    variant: 'contained',
                    size: 'medium',
                    sx: { backgroundColor: 'green', mr: '20px' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={handleSave(true)}
                  buttonText={ "Revise Order" }
                  dialogText={ "Are you sure you want to revise the order?" }
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              }
              {
                paramOrderId !== -1 && 
                <ConfirmationButton
                  buttonProps={{
                    disabled: isSaving,
                    variant: 'contained',
                    size: 'medium',
                    sx: { backgroundColor: 'blue', mr: '20px' },
                    style: buttonStyle
                  }}
                  handleDialogClickYes={handleDuplicate(true)}
                  buttonText={ "Duplicate" }
                  dialogText={ "Are you sure you want to duplicate the order?" }
                  dialogYesText="Confirm"
                  dialogNoText="Cancel"
                />
              }
              <Button variant="outlined" size="normal" style={buttonStyle} onClick={() => navigate(backUrl)}>
                Back
              </Button>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
